<template>
	<div>
		<AppBoxTheme :options="options" @proceed="proceed"></AppBoxTheme>
	</div>
</template>

<script>
import AppBoxTheme from "@/components/ui/AppBoxTheme";

export default {
	name: "KnowledgeOrganizers",

	data() {
		return {
			options: [
				{
					title: "app.ko.literature.title",
					icon: "literature",
					route: {
						name: "Literature",
					},
				},
				{
					title: "app.ko.history.title",
					icon: "history",
					route: {
						name: "History",
					},
				},
				{
					title: "app.ko.multidisciplinary_action.title",
					icon: "research",
					route: null,
					fade: true,
				},
				{
					title: "app.ko.civic_studies.title",
					icon: "civic-media",
					route: {
						name: "CivicStudies",
					},
				},
				{
					title: "app.ko.bible.title",
					icon: "book",
					route: {
						name: "Bible",
					},
				},
				{
					title: "app.ko.science.title",
					icon: "chemistry",
					route: {
						name: "Science",
					},
				},
			],
		};
	},

	components: {
		AppBoxTheme,
	},

	methods: {
		async proceed(route) {
			try {
				this.$loader.start();

				if (!route) {
					return;
				}

				this.$router.push({ name: route.name });
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
